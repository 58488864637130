.menu-main{
    width: 6vw;
    background-color: var(--main-color);
    position: sticky;
    top: 0;
    height: 100vh;  
}

.menu-list{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    font-size: large;
    height: 50%;
    padding: 0 5px;
    transform: translateX(-100%)
}

.menu-item{
    text-align: center;
    font-family: var(--content-text);
}

.menu-item a{
    color: white;
    text-decoration: none;
}

.menu-item a:hover{
    color: white;
    text-decoration: underline;
}


.show{
    animation-name: grow;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    
}

.hide{
    animation-name: shrink;
    animation-duration: 0.75s;
    animation-fill-mode: forwards;

}

@keyframes grow {
    from {transform: translateX(-100%);}
    to {transform: translateX(0%);}
}

@keyframes shrink {
    0% {transform: translateX(0%);}
    35% {transform: translateX(0%);}
    100% {transform: translateX(-100%);}
}


@media only screen and (max-width: 850px) {
    .menu-main{
        width: 100vw;
        background-color: var(--main-color);
        padding: 5px 0px;
        position: sticky;
        top: 0;
        height: 10vh;
        z-index: 100; 
    }
    
    .menu-list{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        font-size: large;
        height: 90%;
        padding: 5px 0px;
        transform: translateX(-100%)  
    }
  }

