p{
    margin: 0;
}

.chart-page{
    display: flex;
}


.div2{
    width: 100vw;
    align-items: center;
    
}

.upper-section{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    width: 100%;
    text-align: center;
    color:white;
    box-shadow: 0 1px 2px rgba(0,0,0,0.02), 
                0 2px 4px rgba(0,0,0,0.02), 
                0 4px 8px rgba(0,0,0,0.02), 
                0 8px 16px rgba(0,0,0,0.02),
                0 16px 32px rgba(0,0,0,0.02), 
               0 32px 64px rgba(0,0,0,0.05);
}

.chart-text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.chart-text h7{
    margin: 0;
    font-size: 7vw;
    font-family: var(--title-font);
}

.chart-text p{
    font-size: small;
    font-family: var(--content-text);
}

.disclaimer{
    width: 50%;
}

.chart-content{
    padding-top: 40px;
    width: 100%;
}

.category{
    display:flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    max-width:90vw;
}

.search-dropdown{
    display: flex;
    width: 100%;
 
}

.search{
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 100px;
    width: 6vw;
    height: 50px;
    background-color: var(--filter-by-color);
    color: black;
    font-family: var(--content-text);
    text-align: center;
    
}

.search-buttons{
    display: flex;
    justify-content: space-around;
    width: 100%;
    height: 50px;
    overflow: hidden;
}


.search-tab{
    border: none;
    background-color: var(--filter-by-color);
    font-family: var(--content-text);
    font-size: 16px;
    min-width: 25%;
    height: 100%;
}

button:hover{
    background-color: var(--main-color);
    color: white;
}


@media only screen and (max-width: 850px) {
    .chart-page{
        display: flex;
        flex-direction: column;
    }

    .chart-content{
        padding-top: 40px;
        width: 100%;
    }

    .category{
        margin: 0;
        max-width: 100vw;
    }

    .search{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        min-width: 100px;
        width: 6vw;
        height: 50px;
        background-color: var(--filter-by-color);
        color: black;
        font-family: var(--content-text);
        text-align: center;
        
    }
    
    .search-buttons{
        display: flex;
        flex-wrap: wrap;
        justify-content: unset;
        width: 100%;
        height: 50px;
        overflow: visible;
        padding-bottom: 20px;
    }
    
    
    .search-tab{
        border: none;
        background-color: var(--filter-by-color);
        font-family: var(--content-text);
        font-size: 16px;
        min-width: 50%;
    }


  }