html{
    overflow: hidden;
}

body{
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
}

.home{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-content{
    display: flex;
    flex-direction: column;
    width: 100vw;
    min-height: 100vh;
    margin-top: 10px;
}


