.card{
    box-shadow: 0 1px 2px rgba(0,0,0,0.02), 
                0 2px 4px rgba(0,0,0,0.02), 
                0 4px 8px rgba(0,0,0,0.02), 
                0 8px 16px rgba(0,0,0,0.02),
                0 16px 32px rgba(0,0,0,0.02), 
               0 32px 64px rgba(0,0,0,0.05);
    background-color: rgba(255, 255, 255, 0.747);
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    border-radius: 10px;
    width: 45%;
}

.card-title{
    width: 40%;
    padding: 1vw;
    display: flex;
    flex-direction: column;
    align-self: center;
}

.card-text-title{
    font-family: var(--sub-title);
    text-align: center;
}

.card-text{
    font-family: var(--content-text); 
}

.card-content{
    display: flex;
    flex-direction: column;
    align-self: center;
    width: 60%;
    padding: 2vw;
    font-family: var(--content-text);
}

.card-content h4{
    margin-bottom: 0;
}

.card-content button{
    padding: 15px;
    margin-top: 40px;
    background-color: var(--main-color);
    color: white;
    border: none;
    border-radius: 10px;
    font-family: var(--content-text);
}

.card-image{
    max-width: auto;
    max-height: 100%;
}

@media only screen and (max-width: 1200px) {
    .card{
        display: flex;
        flex-direction: row;
        border-radius: 5px 5px 20px 20px;
        width: 95%;
    }

    .card-title{
        display: flex;
        flex-direction: column;
        align-items: center;
        border-right: none;
        text-align: center;
        width: 100%;
        
    }

    .card-image{
        max-width: 50%;
        max-height: auto;
    }

    .card-content{
        display: flex;
        width: 90%;
        padding: 20px 2vw;
        text-align: center;
    }
  }

  @media only screen and (max-width: 850px) {
    .card{
        display: flex;
        flex-direction: column;
        border-radius: 5px 5px 20px 20px;
        width: 95%;
    }

    .card-title{
        border-right: none;
        text-align: center;
        width: 100%;
        
    }

    .card-image{
        max-width: 50%;
        max-height: auto;
    }

    .card-content{
        display: flex;
        width: 90%;
        padding: 2vw;
        text-align: center;
    }
  }


