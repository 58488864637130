.homecard{
    margin: 0 0 100px 0;
}

.homecard-div{
    background-image: url('../../images/stacked-books.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 75vh;
}

.homecard-content{
    width: 100vw;
    height: 75vh;
    display: flex;
    justify-content: flex-end;
    background: rgba(255, 255, 255, 0.144);
    color: white;
}

.img-div{
    width: 50vw;
    height: 75vh;
    background-image: url('../../images/logo.png') ;
    background-repeat: no-repeat;
    background-position: center;
}

.text-div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px;
    font-family: var(--content-text);
    box-shadow: 0 1px 2px rgba(0,0,0,0.02), 
                0 2px 4px rgba(0,0,0,0.02), 
                0 4px 8px rgba(0,0,0,0.02), 
                0 8px 16px rgba(0,0,0,0.02),
                0 16px 32px rgba(0,0,0,0.02), 
                0 32px 64px rgba(0,0,0,0.05);
}

.text-div h7{
    width: 40%;
}

.text-div p{
    width: 40%;
    font-size: large;
}

.homecard-h1{
    font-size: 45px;
    border: none;
    margin: 0;
    font-family: var(--title-font);
}


@media only screen and (max-width: 850px) {
    .text-div{
        display: flex;
        flex-direction: column;
        padding: 20px 0px;
    }

    .text-div h7{
        width: 90%;
        padding-bottom: 20px;
    }
    
    .text-div p{
        width: 90%;
        font-size: large;
    }

    .img-div{
        width: 80vw;
        height: 85vh;
        background-image: url('../../images/logo.png') ;
        background-repeat: no-repeat;
        background-position: center;
    }
    
}