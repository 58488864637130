hr{
    width: 70%
}

button{
    cursor: pointer;
}

.dropdown-page{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dropdown{
    background-color: white;
    padding: 2vw;
    width: 75vw;
}

.dropdown-title{
    font-size: xxx-large;
    margin: 0;
    padding:0px 0px 30px 0px;
    font-family: var(--title-font);
}

.dropdowntext-reverse{
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    background-color: white;
    
}

.dropdown-text{
    display: flex;
    align-self: center;
    justify-content: space-around;
}

.dropdown-img-div{
    align-self: center;
    display: flex;
}

.dropdown-img{
    height: auto;
    width: 100%;
    margin: 0;
}

.info{
    font-size: large;
    padding: 4px;
    text-align: center;
    align-self: center;
    font-family: var(--content-text);
}

.info button{
    padding: 15px;
    margin-top: 40px;
    background-color: var(--main-color);
    color: white;
    border: none;
    border-radius: 10px;
}


@media only screen and (max-width: 850px) {
    .dropdown-text, .dropdowntext-reverse{
        display: flex;
        flex-direction: column;
    }

    .info{
        padding: 30px 0px;
    }
}
