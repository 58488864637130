@import url(https://fonts.googleapis.com/css2?family=Crimson+Pro&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lora&family=Open+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lora&family=Open+Sans&display=swap);
hr{
    width: 70%
}

button{
    cursor: pointer;
}

.dropdown-page{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dropdown{
    background-color: white;
    padding: 2vw;
    width: 75vw;
}

.dropdown-title{
    font-size: xxx-large;
    margin: 0;
    padding:0px 0px 30px 0px;
    font-family: var(--title-font);
}

.dropdowntext-reverse{
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    background-color: white;
    
}

.dropdown-text{
    display: flex;
    align-self: center;
    justify-content: space-around;
}

.dropdown-img-div{
    align-self: center;
    display: flex;
}

.dropdown-img{
    height: auto;
    width: 100%;
    margin: 0;
}

.info{
    font-size: large;
    padding: 4px;
    text-align: center;
    align-self: center;
    font-family: var(--content-text);
}

.info button{
    padding: 15px;
    margin-top: 40px;
    background-color: var(--main-color);
    color: white;
    border: none;
    border-radius: 10px;
}


@media only screen and (max-width: 850px) {
    .dropdown-text, .dropdowntext-reverse{
        display: flex;
        flex-direction: column;
    }

    .info{
        padding: 30px 0px;
    }
}

.homecard{
    margin: 0 0 100px 0;
}

.homecard-div{
    background-image: url(/static/media/stacked-books.6d93332c.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 75vh;
}

.homecard-content{
    width: 100vw;
    height: 75vh;
    display: flex;
    justify-content: flex-end;
    background: rgba(255, 255, 255, 0.144);
    color: white;
}

.img-div{
    width: 50vw;
    height: 75vh;
    background-image: url(/static/media/logo.e6c1dd29.png);
    background-repeat: no-repeat;
    background-position: center;
}

.text-div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px;
    font-family: var(--content-text);
    box-shadow: 0 1px 2px rgba(0,0,0,0.02), 
                0 2px 4px rgba(0,0,0,0.02), 
                0 4px 8px rgba(0,0,0,0.02), 
                0 8px 16px rgba(0,0,0,0.02),
                0 16px 32px rgba(0,0,0,0.02), 
                0 32px 64px rgba(0,0,0,0.05);
}

.text-div h7{
    width: 40%;
}

.text-div p{
    width: 40%;
    font-size: large;
}

.homecard-h1{
    font-size: 45px;
    border: none;
    margin: 0;
    font-family: var(--title-font);
}


@media only screen and (max-width: 850px) {
    .text-div{
        display: flex;
        flex-direction: column;
        padding: 20px 0px;
    }

    .text-div h7{
        width: 90%;
        padding-bottom: 20px;
    }
    
    .text-div p{
        width: 90%;
        font-size: large;
    }

    .img-div{
        width: 80vw;
        height: 85vh;
        background-image: url(/static/media/logo.e6c1dd29.png);
        background-repeat: no-repeat;
        background-position: center;
    }
    
}
html{
    overflow: hidden;
}

body{
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
}

.home{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-content{
    display: flex;
    flex-direction: column;
    width: 100vw;
    min-height: 100vh;
    margin-top: 10px;
}



.card{
    box-shadow: 0 1px 2px rgba(0,0,0,0.02), 
                0 2px 4px rgba(0,0,0,0.02), 
                0 4px 8px rgba(0,0,0,0.02), 
                0 8px 16px rgba(0,0,0,0.02),
                0 16px 32px rgba(0,0,0,0.02), 
               0 32px 64px rgba(0,0,0,0.05);
    background-color: rgba(255, 255, 255, 0.747);
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    border-radius: 10px;
    width: 45%;
}

.card-title{
    width: 40%;
    padding: 1vw;
    display: flex;
    flex-direction: column;
    align-self: center;
}

.card-text-title{
    font-family: var(--sub-title);
    text-align: center;
}

.card-text{
    font-family: var(--content-text); 
}

.card-content{
    display: flex;
    flex-direction: column;
    align-self: center;
    width: 60%;
    padding: 2vw;
    font-family: var(--content-text);
}

.card-content h4{
    margin-bottom: 0;
}

.card-content button{
    padding: 15px;
    margin-top: 40px;
    background-color: var(--main-color);
    color: white;
    border: none;
    border-radius: 10px;
    font-family: var(--content-text);
}

.card-image{
    max-width: auto;
    max-height: 100%;
}

@media only screen and (max-width: 1200px) {
    .card{
        display: flex;
        flex-direction: row;
        border-radius: 5px 5px 20px 20px;
        width: 95%;
    }

    .card-title{
        display: flex;
        flex-direction: column;
        align-items: center;
        border-right: none;
        text-align: center;
        width: 100%;
        
    }

    .card-image{
        max-width: 50%;
        max-height: auto;
    }

    .card-content{
        display: flex;
        width: 90%;
        padding: 20px 2vw;
        text-align: center;
    }
  }

  @media only screen and (max-width: 850px) {
    .card{
        display: flex;
        flex-direction: column;
        border-radius: 5px 5px 20px 20px;
        width: 95%;
    }

    .card-title{
        border-right: none;
        text-align: center;
        width: 100%;
        
    }

    .card-image{
        max-width: 50%;
        max-height: auto;
    }

    .card-content{
        display: flex;
        width: 90%;
        padding: 2vw;
        text-align: center;
    }
  }



.menu-main{
    width: 6vw;
    background-color: var(--main-color);
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    height: 100vh;  
}

.menu-list{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    font-size: large;
    height: 50%;
    padding: 0 5px;
    transform: translateX(-100%)
}

.menu-item{
    text-align: center;
    font-family: var(--content-text);
}

.menu-item a{
    color: white;
    text-decoration: none;
}

.menu-item a:hover{
    color: white;
    text-decoration: underline;
}


.show{
    -webkit-animation-name: grow;
            animation-name: grow;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    
}

.hide{
    -webkit-animation-name: shrink;
            animation-name: shrink;
    -webkit-animation-duration: 0.75s;
            animation-duration: 0.75s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;

}

@-webkit-keyframes grow {
    from {transform: translateX(-100%);}
    to {transform: translateX(0%);}
}

@keyframes grow {
    from {transform: translateX(-100%);}
    to {transform: translateX(0%);}
}

@-webkit-keyframes shrink {
    0% {transform: translateX(0%);}
    35% {transform: translateX(0%);}
    100% {transform: translateX(-100%);}
}

@keyframes shrink {
    0% {transform: translateX(0%);}
    35% {transform: translateX(0%);}
    100% {transform: translateX(-100%);}
}


@media only screen and (max-width: 850px) {
    .menu-main{
        width: 100vw;
        background-color: var(--main-color);
        padding: 5px 0px;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        height: 10vh;
        z-index: 100; 
    }
    
    .menu-list{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        font-size: large;
        height: 90%;
        padding: 5px 0px;
        transform: translateX(-100%)  
    }
  }


p{
    margin: 0;
}

.chart-page{
    display: flex;
}


.div2{
    width: 100vw;
    align-items: center;
    
}

.upper-section{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    width: 100%;
    text-align: center;
    color:white;
    box-shadow: 0 1px 2px rgba(0,0,0,0.02), 
                0 2px 4px rgba(0,0,0,0.02), 
                0 4px 8px rgba(0,0,0,0.02), 
                0 8px 16px rgba(0,0,0,0.02),
                0 16px 32px rgba(0,0,0,0.02), 
               0 32px 64px rgba(0,0,0,0.05);
}

.chart-text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.chart-text h7{
    margin: 0;
    font-size: 7vw;
    font-family: var(--title-font);
}

.chart-text p{
    font-size: small;
    font-family: var(--content-text);
}

.disclaimer{
    width: 50%;
}

.chart-content{
    padding-top: 40px;
    width: 100%;
}

.category{
    display:flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    max-width:90vw;
}

.search-dropdown{
    display: flex;
    width: 100%;
 
}

.search{
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 100px;
    width: 6vw;
    height: 50px;
    background-color: var(--filter-by-color);
    color: black;
    font-family: var(--content-text);
    text-align: center;
    
}

.search-buttons{
    display: flex;
    justify-content: space-around;
    width: 100%;
    height: 50px;
    overflow: hidden;
}


.search-tab{
    border: none;
    background-color: var(--filter-by-color);
    font-family: var(--content-text);
    font-size: 16px;
    min-width: 25%;
    height: 100%;
}

button:hover{
    background-color: var(--main-color);
    color: white;
}


@media only screen and (max-width: 850px) {
    .chart-page{
        display: flex;
        flex-direction: column;
    }

    .chart-content{
        padding-top: 40px;
        width: 100%;
    }

    .category{
        margin: 0;
        max-width: 100vw;
    }

    .search{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        min-width: 100px;
        width: 6vw;
        height: 50px;
        background-color: var(--filter-by-color);
        color: black;
        font-family: var(--content-text);
        text-align: center;
        
    }
    
    .search-buttons{
        display: flex;
        flex-wrap: wrap;
        justify-content: unset;
        width: 100%;
        height: 50px;
        overflow: visible;
        padding-bottom: 20px;
    }
    
    
    .search-tab{
        border: none;
        background-color: var(--filter-by-color);
        font-family: var(--content-text);
        font-size: 16px;
        min-width: 50%;
    }


  }
.about-page{
    display: flex;
    width:100vw;
    
}

.about-div2{
    display: flex;
    flex-direction: column ;
    padding: 20px 20px;
}

.about-us{
    display: flex;
    justify-content: space-between;
    max-width: 100vw;
    background-color: white;
    padding: 20px 50px;
    box-shadow: 0 1px 2px rgba(0,0,0,0.02), 
                0 2px 4px rgba(0,0,0,0.02), 
                0 4px 8px rgba(0,0,0,0.02), 
                0 8px 16px rgba(0,0,0,0.02),
                0 16px 32px rgba(0,0,0,0.02), 
               0 32px 64px rgba(0,0,0,0.05);
    font-size: larger;
    
}

.about-us h1{
    font-family: var(--sub-title);
    margin: 0;
}

.image{
    float:left;
    margin-right: 0.5vw;
}

.grant{
    width: 60%;
}

.grant-text{
    padding-left: 5px;
    font-family:var(--content-text);
}

.about-return{
    display: flex;
    justify-content: space-between;
    max-width: 100vw;
    background-color: white;
    padding: 20px 50px;
    box-shadow: 0 1px 2px rgba(0,0,0,0.02), 
                0 2px 4px rgba(0,0,0,0.02), 
                0 4px 8px rgba(0,0,0,0.02), 
                0 8px 16px rgba(0,0,0,0.02),
                0 16px 32px rgba(0,0,0,0.02), 
               0 32px 64px rgba(0,0,0,0.05);
    font-size: larger;
    
}

.about-return p{
    font-family:var(--content-text);
    width: 60%;
}

.about-subtitle{
    font-family: var(--sub-title);
    margin: 0;
    width: 40%;
}


@media only screen and (max-width: 850px){
    .about-page{  
       display: flex;
       flex-direction: column;
    }

    .about-div2{
        display: flex;
        flex-direction: column ;
        padding: 20px 0px;
    }

    .grant{
        width: 100%;
    }

    .about-us, .about-return{
        display: flex;
        flex-direction: column;
        padding: 20px 15px;
    }

    .about-return p{
        font-family:var(--content-text);
        width: 100%;
    }
    
    .about-subtitle{
        font-family: var(--sub-title);
        margin: 0;
        width: 100%;
    }
}
:root{
    --title-font: 'Crimson Pro', serif;
    --sub-title: 'Lora', serif;
    --content-text: 'Open Sans', sans-serif;
    --main-color: rgb(78, 177, 238);
    --filter-by-color: rgba(189, 210, 223, 0.082);
}
