.about-page{
    display: flex;
    width:100vw;
    
}

.about-div2{
    display: flex;
    flex-direction: column ;
    padding: 20px 20px;
}

.about-us{
    display: flex;
    justify-content: space-between;
    max-width: 100vw;
    background-color: white;
    padding: 20px 50px;
    box-shadow: 0 1px 2px rgba(0,0,0,0.02), 
                0 2px 4px rgba(0,0,0,0.02), 
                0 4px 8px rgba(0,0,0,0.02), 
                0 8px 16px rgba(0,0,0,0.02),
                0 16px 32px rgba(0,0,0,0.02), 
               0 32px 64px rgba(0,0,0,0.05);
    font-size: larger;
    
}

.about-us h1{
    font-family: var(--sub-title);
    margin: 0;
}

.image{
    float:left;
    margin-right: 0.5vw;
}

.grant{
    width: 60%;
}

.grant-text{
    padding-left: 5px;
    font-family:var(--content-text);
}

.about-return{
    display: flex;
    justify-content: space-between;
    max-width: 100vw;
    background-color: white;
    padding: 20px 50px;
    box-shadow: 0 1px 2px rgba(0,0,0,0.02), 
                0 2px 4px rgba(0,0,0,0.02), 
                0 4px 8px rgba(0,0,0,0.02), 
                0 8px 16px rgba(0,0,0,0.02),
                0 16px 32px rgba(0,0,0,0.02), 
               0 32px 64px rgba(0,0,0,0.05);
    font-size: larger;
    
}

.about-return p{
    font-family:var(--content-text);
    width: 60%;
}

.about-subtitle{
    font-family: var(--sub-title);
    margin: 0;
    width: 40%;
}


@media only screen and (max-width: 850px){
    .about-page{  
       display: flex;
       flex-direction: column;
    }

    .about-div2{
        display: flex;
        flex-direction: column ;
        padding: 20px 0px;
    }

    .grant{
        width: 100%;
    }

    .about-us, .about-return{
        display: flex;
        flex-direction: column;
        padding: 20px 15px;
    }

    .about-return p{
        font-family:var(--content-text);
        width: 100%;
    }
    
    .about-subtitle{
        font-family: var(--sub-title);
        margin: 0;
        width: 100%;
    }
}